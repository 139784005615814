import React from 'react'
import Card from '@material-ui/core/Card';
import FlowButton from '../layout/FlowButton';
import ClickButton from '../layout/ClickButton';

const ErrorSent = () => {
    const sour = "(ಥ﹏ಥ)"

    const onClick = () => {
        window.location.reload();
    }
    return (
        <Card raised={true} style={cardStyle}>
            <h1>{sour}</h1>
            <h3>Uh Oh...</h3>
            <h5>Message  Error</h5>
            <p>The message failed to send. Please try again.</p>
            <FlowButton link="/" title="Home" />
            <ClickButton click={onClick} title="Contact" />
        </Card>
    )
}

const cardStyle = {
    backgroundColor: '#36b4b2',
    color: '#ececec',
    marginTop: '5%',
    marginLeft: '5%',
    marginRight: '5%',
    height: '80%',
    padding: '2%',
    fontSize: '150%',
    fontFamily: 'Roboto',
    textAlign: 'center'
}

export default ErrorSent
