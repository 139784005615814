import React, { Fragment } from 'react'
import Home from '../pages/Home'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MobileItem from './MobileItem';

const apps = require('../../data/mobile.json');

const Mobile = () => {
    return (
        <Fragment>
            <Home />
            <div style={{ marginTop: '1%' }}>
                <Grid container spacing={0}>
                    {apps.map(app => (
                        <MobileItem key={app.id}
                            imgSrc={app.imgSrc}
                            isOdd={app.id === 5}
                            description={app.description}
                            github={app.github}
                            appstore={app.appstore}
                            name={app.name}
                        />
                    ))}
                </Grid>
            </div>
            <br />
            <div style={{ textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textAlign: 'center' }}
                    onClick={scrollToTop}>
                    Top
                </Button>
                <br />
                <br />
            </div>
        </Fragment>

    )
}

const scrollToTop = () => {
    window.scrollTo(0, 0)
};

export default Mobile
