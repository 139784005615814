import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card';
import FlowButton from '../layout/FlowButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';



const Home = ({ isHome }) => {
    const isActive = useMediaQuery('(max-width:600px)');
    const sour = "¯\\_(ツ)_/¯"

    return (
        <Card raised={true} style={!isActive ? homeCardStyle : mobileCardStyle}>
            {(!isActive || isHome) && (
                <Fragment>
                    {/* <div style={{ textAlign: 'center' }}>
                        <img src="../../../me.PNG" alt="me" style={imgStyle} />
                        <p style={{ fontSize: '90%' }}>a nice picture of myself</p>
                    </div> */}
                    <h1 style={{ textAlign: 'center' }}>Unnecessary Background</h1>
                    <h3 style={{ textAlign: 'center' }}>Read at your discretion {sour}</h3>
                    <p>
                        My name is Florian Laplanche and I am a software developer. My first experience with coding came when I was 14 years old while learning about web development in high school. Though the webpages I created were simplistic initially, I always felt like the process was fascinating. As time elapsed with the rise of mobile computing, my interest in application development began to expand, specifically iOS development. While in college,  I started to teach myself Objective-C but then decided to dedicate more of my time to learning Swift after it was released by Apple. Over the years I’ve made it an honest personal endeavor to constantly broaden and increase my knowledge of programming languages in a rapidly evolving society. During this time, I have worked with a few languages such as Java, Javascript, Swift, and C++. With technology changing and adapting each day, I still have much more to learn. I believe that my sincere passion, willingness to learn and innovate, and strong acumen in technology will make me an asset to the professional world of development.
                </p>
                </Fragment>
            )}
            {isActive && scrollToTop}
            <div style={{ textAlign: 'center' }}>
                <FlowButton link="/mobile" title="Mobile Applications" />
                <FlowButton link="/web" title="Web Applications" />
            </div>

        </Card>
    )
}

Home.propTypes = {
    isHome: PropTypes.bool,
}

// const imgStyle = {
//     width: '15%',
//     height: '15%',
// }

const homeCardStyle = {
    backgroundColor: '#36b4b2',
    color: '#ececec',
    marginTop: '1.25%',
    marginLeft: '1%',
    marginRight: '1%',
    padding: '2%',
    fontSize: '105%',
    fontFamily: 'Roboto',
    textAlign: 'justify',
}

const mobileCardStyle = {
    backgroundColor: '#36b4b2',
    color: '#ececec',
    marginTop: '2%',
    marginLeft: '2%',
    marginRight: '2%',
    padding: '5%',
    fontSize: '105%',
    fontFamily: 'Roboto',
    textAlign: 'justify',
}

const scrollToTop = () => {
    window.scrollTo(0, 0)
}

export default Home
