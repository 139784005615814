import React from 'react';
import PropTypes from 'prop-types'
import ClickButton from '../layout/ClickButton';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';

const WebItem = ({ imgSrc, description, github, website, name, id }) => {

    return (
        <Grid item xs={12} sm={6} md={6}>
            <Card raised={true} style={cardStyle}>
                <Grid container spacing={1}>
                    <Grid item sm={12} >
                        <img src={imgSrc} alt="img" style={imgStyle} />
                    </Grid>
                    <Grid item sm={12}>
                        <div style={sectionStyle}>
                            <h3>{name}</h3>
                            <div style={{ textAlign: 'justify' }}>{description}</div>
                            <br />
                            {(id === 4) && (<div><br /> <br /></div>)}
                            <div style={{ textAlign: 'center' }}>
                                <ClickButton web={github} title="Github" />
                                <ClickButton web={website} title="Website" />
                            </div>
                        </div>
                    </Grid>
                    {/* <Grid sm={12}>
                        <ClickButton web={github} title="Github" />
                        <ClickButton web={website} title="Website" />
                    </Grid> */}
                </Grid>
            </Card>
        </Grid>
    )

    // return (
    //     <Grid item sm={6}>
    //         <Card raised={true} style={cardStyle}>
    //             <img src={imgSrc} alt="img" style={imgStyle} />
    //             <h3>{name}</h3>
    //             <p>
    //                 {description}
    //             </p>
    //             <Fragment>
    //                 <ClickButton href={github} title="Github" />
    //                 <ClickButton href={website} title="Website" />
    //             </Fragment>
    //         </Card>
    //     </Grid>
    // )
}

WebItem.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    github: PropTypes.string.isRequired,
    website: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

const cardStyle = {
    backgroundColor: '#36b4b2',
    color: '#ececec',
    padding: '4%',
    margin: '2%',
    fontFamily: 'Roboto',
    textAlign: 'center',

}

const sectionStyle = {
    height: "100%",
    paddingTop: 5,
};

const imgStyle = {
    width: '100%',
    height: '100%',
}

export default WebItem
