import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const FlowTextField = ({ handleChange, name, label, placeholder, required, multiline, rows }) => {
    const classes = useStyles();
    return (
        <TextField
            id="outlined-full-width"
            label={label}
            name={name}
            style={{ margin: 8 }}
            placeholder={placeholder}
            fullWidth
            rows={rows}
            multiline={multiline}
            required={required}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
                shrink: true,
                classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                }
            }}
            InputProps={{
                classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                }
            }}
        />
    )
}

FlowTextField.propTypes = {
    handleChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    multiline: PropTypes.bool,
    rows: PropTypes.string,
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    cssLabel: {
        color: '#36b4b2'
    },

    cssOutlinedInput: {
        '&$cssFocused $notchedOutline': {
            borderColor: `${theme.palette.primary.main} !important`,
        }
    },

    cssFocused: {},

    notchedOutline: {
        borderWidth: '1px',
        borderColor: '#36b4b2 !important'
    },

}));

export default FlowTextField
