import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const ClickButton = ({ click, title, web, disabled }) => {
    const classes = useStyles();
    return (
        <Button variant="contained"
            href={web} color="secondary"
            className={classes.button}
            onClick={click}
            disabled={disabled}>
            {title}
        </Button>
    )
}

ClickButton.propTypes = {
    click: PropTypes.func,
    title: PropTypes.string.isRequired,
    web: PropTypes.string,
    disabled: PropTypes.bool,
}

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));

export default ClickButton
