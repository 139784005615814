import React, { Fragment } from 'react'
import NavBarNormal from './NavBarNormal'
import NavBarMobile from './NavBarMobile'
import useMediaQuery from '@material-ui/core/useMediaQuery';


const NavBar = () => {
    const isActive = useMediaQuery('(max-width:600px)');

    return (
        <Fragment>
            {!isActive ? <NavBarNormal /> : <NavBarMobile />}
        </Fragment>

    )
}

export default NavBar
