import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';


const NavBarMobile = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = e => {
        setAnchorEl(e.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (

        <AppBar position="static">
            <Toolbar>

                <Typography variant="h6" className={classes.title}>
                    <Link to='/' style={{ textDecoration: 'none', color: '#ececec' }}>
                        Florian Laplanche
                        </Link>
                </Typography>

                <div>
                    <IconButton
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                    >
                        <i className="fas fa-bars" style={{ color: '#ececec' }}></i>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {/* <MenuItem onClick={handleClose}>
                            <Link to='/web'>
                                <i className="fas fa-desktop" style={blueBtn}></i>
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Link to='/mobile'>
                                <i className="fas fa-mobile-alt" style={blueBtn}></i>
                            </Link>
                        </MenuItem> */}
                        <MenuItem onClick={handleClose}>
                            <a href='../../../softwareDevResume.pdf' download>
                                <i className="fas fa-file-download" style={blueBtn}></i>
                            </a>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <a href="https://github.com/FlorianML">
                                <i className="fab fa-github" style={blueBtn}></i>
                            </a>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <Link to='/contact'>
                                <i className="fa fa-envelope-open-text" style={blueBtn}></i>
                            </Link>
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <a href="https://linkedin.com/in/florianlap">
                                <i className="fab fa-linkedin-in" style={blueBtn}></i>
                            </a>
                        </MenuItem>
                    </Menu>
                </div>

            </Toolbar>
        </AppBar>
    )
}

const blueBtn = {
    color: '#36b4b2'
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },

}));

export default NavBarMobile