import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const FlowButton = ({ link, title }) => {
    const classes = useStyles();
    return (
        <Link to={link} style={{ textDecoration: 'none' }}>
            <Button variant="contained" color="secondary" className={classes.button}>
                {title}
            </Button>
        </Link>
    )
}

FlowButton.propTypes = {
    link: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    input: {
        display: 'none',
    },
}));

export default FlowButton
