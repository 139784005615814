import React from 'react'
import Card from '@material-ui/core/Card';
import FlowButton from '../layout/FlowButton';

const NotFound = () => {
    const sour = "(ಥ﹏ಥ)"
    const smiley = "\\ (•◡•) /"
    return (
        <Card raised={true} style={cardStyle}>
            <h1>{sour}</h1>
            <h3>Uh Oh...</h3>
            <h5>Page Not Found</h5>
            <p>Here are some links to get you back {smiley}</p>
            <FlowButton link="/" title="Home" />
            <FlowButton link="/web" title="Web Applications" />
            <FlowButton link="/mobile" title="Mobile Applications" />
        </Card>
    )
}

const cardStyle = {
    backgroundColor: '#36b4b2',
    color: '#ececec',
    marginTop: '5%',
    marginLeft: '5%',
    marginRight: '5%',
    height: '80%',
    padding: '2%',
    fontSize: '150%',
    fontFamily: 'Roboto',
    textAlign: 'center'
}

export default NotFound
