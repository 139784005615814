import React from 'react'
import Card from '@material-ui/core/Card';
import FlowButton from '../layout/FlowButton';

const MessageSent = () => {
    const smiley = "\\ (•◡•) /"
    return (
        <Card className='message-sent' raised={true} style={cardStyle}>
            <img src="../../../../message-sent.svg" alt="email" style={{ width: '25%', height: '25%' }} />

            <h5>Message Sent {smiley}</h5>
            <p>Feel free to keep looking. Here are some links to get you going.</p>
            <FlowButton link="/" title="Home" />
            <FlowButton link="/web" title="Web Applications" />
            <FlowButton link="/mobile" title="Mobile Applications" />
        </Card>
    )
}

const cardStyle = {
    backgroundColor: '#36b4b2',
    color: '#ececec',
    marginTop: '5%',
    marginLeft: '5%',
    marginRight: '5%',
    height: '80%',
    padding: '2%',
    fontSize: '150%',
    fontFamily: 'Roboto',
    textAlign: 'center'
}

export default MessageSent
