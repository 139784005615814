import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';


const NavBarNormal = () => {
    const classes = useStyles();

    return (
        <div className={classes.root} style={{ marginLeft: '1%', marginRight: '1%', marginTop: '1%' }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <Link to='/' style={{ textDecoration: 'none', color: '#ececec' }}>
                            Florian Laplanche
                        </Link>
                    </Typography>
                    <Button color="inherit">
                        <a href='../../../florian_softwareResume.pdf' download>
                            <i className="fas fa-file-download" style={whiteBtn}></i>
                        </a>
                    </Button>
                    <Button color="inherit">
                        <a href="https://github.com/FlorianML">
                            <i className="fab fa-github" style={whiteBtn}></i>
                        </a>
                    </Button>
                    <Button color="inherit">
                        <Link to='/contact'>
                            <i className="fa fa-envelope-open-text" style={whiteBtn}></i>
                        </Link>
                    </Button>
                    <Button color="inherit">
                        <a href="https://linkedin.com/in/florianlap">
                            <i className="fab fa-linkedin-in" style={whiteBtn}></i>
                        </a>
                    </Button>
                </Toolbar>
            </AppBar>
        </div>
    )
}

const whiteBtn = {
    color: '#ececec'
}

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },

}));

export default NavBarNormal
