import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card';
import ClickButton from '../layout/ClickButton'
import Grid from '@material-ui/core/Grid';

const MobileItem = ({ imgSrc, description, github, appstore, name, isOdd }) => {

    return (
        <Grid item xs={12} sm={6} md={6}>
            <Card raised={true} style={cardStyle}>
                <Grid container spacing={1}>
                    <Grid item sm={12} md={6}>
                        <img src={imgSrc} alt="img" style={!isOdd ? imgStyle : haImgStyle} />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <h3>{name}</h3>
                        {description}
                        {isOdd &&
                            <Fragment>
                                <p>
                                    Celebrities are parodied and named differently but include:
                                </p>
                                <ul style={{ textAlign: 'justify', listStyle: 'inside' }}>
                                    <li>Kanye West</li>
                                    <li>Beyonce</li>
                                    <li>Rihanna</li>
                                    <li>Drake</li>
                                    <li>Morgan Freeman</li>
                                    <li>Kim Kardashian</li>
                                    <li>And More</li>
                                </ul>
                            </Fragment>
                        }
                        <div style={{ textAlign: 'center' }}>
                            <br />
                            <ClickButton web={github} title="Github" />
                            <ClickButton web={appstore} disabled={appstore === ''} title="App Store" />
                        </div>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    )
}

MobileItem.propTypes = {
    imgSrc: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    github: PropTypes.string.isRequired,
    appstore: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isOdd: PropTypes.bool,
}


const cardStyle = {
    backgroundColor: '#36b4b2',
    color: '#ececec',
    padding: '4%',
    margin: '2%',
    fontFamily: 'Roboto',
    textAlign: 'center',

}

const imgStyle = {
    width: '90%',
    height: '90%',
    paddingTop: '5%',
    marginLeft: '5%',
    marginTop: '5%',
    float: 'left',
    borderRadius: '6%',
    display: 'inline'
}

const haImgStyle = {
    width: '100%',
    height: '100%',
}

export default MobileItem
