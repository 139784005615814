import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import ContactForm from './ContactForm'
import MessageSent from './MessageSent'
import ErrorSent from './ErrorSent'

const Contact = () => {
    const [success, setSuccess] = useState(null)

    useEffect(() => {

    }, [success])


    const onSubmit = async (email, subject, body) => {
        axios({
            method: 'post',
            url: 'https://app.99inbound.com/api/e/np07TSTq',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            data: {
                email,
                subject,
                body
            }
        }).then((response) => {
            if (response.status === 200 || 201 || 202) {
                setSuccess(true)
            } else {
                setSuccess(false)
            }
        })
    }

    if (success === null) {
        return <ContactForm onSubmit={onSubmit} />
    }

    return (
        <Fragment>
            {success ? (<MessageSent />) : (<ErrorSent />)}
        </Fragment>
    )
}


export default Contact
