import React, { Fragment } from 'react'
import Home from '../pages/Home'
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import WebItem from './WebItem';


const websites = require('../../data/web')


const Web = () => {

    return (
        <Fragment>
            <Home />
            <div style={{ marginTop: '1%' }}>
                <Grid container spacing={0}>
                    {websites.map(website => (
                        <WebItem key={website.id}
                            id={website.id}
                            imgSrc={website.imgSrc}
                            description={website.description}
                            github={website.github}
                            website={website.website}
                            name={website.name}
                        />
                    ))}
                </Grid>
            </div>
            <br />
            <div style={{ textAlign: 'center' }}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ textAlign: 'center' }}
                    onClick={scrollToTop}>
                    Top
                </Button>
                <br />
                <br />
            </div>
        </Fragment>
    )
}

const scrollToTop = () => {
    window.scrollTo(0, 0)
};

export default Web
