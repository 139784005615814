import React, { useState, useEffect } from 'react'
import FlowTextField from '../layout/FlowTextField'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';

const ContactForm = ({ onSubmit }) => {

    const classes = useStyles();
    const [isValid, setIsValid] = useState(false);
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('No subject');
    const [body, setBody] = useState('');


    useEffect(() => {
        if (email.length > 0 && emailIsValid(email) && body.length > 0) {
            setIsValid(true)
        } else {
            setIsValid(false)
        }
    }, [email, body])

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const submit = () => {
        onSubmit(email, subject, body)
    }

    return (
        <div>
            <Card className="contact-form" raised={true} style={cardStyle}>
                <img src="../../../../message.svg" alt="email" style={{ width: '25%', height: '25%' }} />

                <form className={classes.container} autoComplete="off">
                    <FlowTextField label="Email" required={true} name='email' placeholder='Enter email...'
                        handleChange={e => setEmail(e.target.value)} />
                    <br />
                    <FlowTextField label="Subject" name='subject' placeholder='Enter subject...'
                        handleChange={e => setSubject(e.target.value)} />
                    <br />
                    <FlowTextField label="Body" required={true} multiline={true} rows="6" name='body' placeholder='Enter message here...'
                        handleChange={e => setBody(e.target.value)} />
                </form>
                <br />
                <br />
                <br />
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={submit}
                    disabled={!isValid}>
                    Submit
                </Button>
            </Card>
            <br />
            <br />
            <br />
        </div>
    )
}


const cardStyle = {
    backgroundColor: '#ececec',
    color: '#ececec',
    padding: '2%',
    fontSize: '105%',
    fontFamily: 'Roboto',
    textAlign: 'center'
}

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    }
}));

export default ContactForm
