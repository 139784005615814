import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from './components/layout/NavBar';
import Web from './components/web/Web';
import Home from './components/pages/Home';
import Contact from './components/contact/Contact';
import Mobile from './components/mobile/Mobile';
import NotFound from './components/pages/NotFound';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import './App.css';

function App() {

  return (
    <Router>
      <div className="App" >
        <MuiThemeProvider theme={theme}>
          <NavBar />
          {/* <div style={{ paddingTop: '64px' }}> */}
          <Switch>
            <Route exact path="/" render={props => (<Home isHome={true} />)} />
            <Route exact path="/web" component={Web} />
            <Route exact path="/mobile" component={Mobile} />
            <Route exact path="/contact" component={Contact} />
            <Route component={NotFound} />
          </Switch>
          {/* </div> */}
        </MuiThemeProvider>
      </div>
    </Router>

  );
}


const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#36b4b2',
      contrastText: '#ececec',
    },
    secondary: {
      main: '#ececec',
      contrastText: '#36b4b2',
    }
  }
});


export default App;
